import React, { Fragment } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { NotFoundIllustration, Head } from 'components';
import { H1, P, TextButton } from 'style';
import {
  desktopWidth,
  mobileHdWidth,
  paddingLg,
  paddingWrapperSm,
  paddingWrapperMd,
  paddingWrapperLg,
} from 'style/constants';

const tabletBreak = '955px';
const mobileBreak = '750px';

const Container = styled.div`
  width: 100%;
  flex: 1;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: ${desktopWidth};
  padding: 20px ${paddingWrapperMd} ${paddingWrapperLg};
  box-sizing: border-box;
  margin: 0 auto;

  @media (max-width: ${desktopWidth}) {
    padding-top: 40px;
  }

  @media (max-width: ${tabletBreak}) {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: ${paddingWrapperMd};
  }

  @media (max-width: ${mobileHdWidth}) {
    padding-left: ${paddingWrapperSm};
    padding-right: ${paddingWrapperSm};
  }
`;

const LeftWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 40px;
  flex: 1;
  flex-shrink: 0;

  @media (max-width: ${tabletBreak}) {
    padding-left: 0;
    padding-right: 5px;
  }
`;

const IllustrationContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;

  @media (max-width: ${tabletBreak}) {
    margin: 0 auto;
    transform: scale(0.8);
    max-width: 400px;
  }
`;

const IllustrationWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;

  @media (max-width: ${tabletBreak}) {
    transform: scale(1.05);
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-left: 100px;
  width: 60%;
  flex: 1;

  @media (max-width: ${tabletBreak}) {
    padding-top: 20px;
    padding-left: 0;
    align-items: center;
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 500px;

  h1 {
    font-weight: 700;
    font-size: 56px;
    line-height: 56px;
    padding-bottom: ${paddingLg};
  }

  p {
    padding-bottom: ${paddingLg};
  }

  a {
    transform: translateX(-12px);
  }

  @media (max-width: ${mobileBreak}) {
    max-width: 400px;
  }
`;

const NotFoundPage = ({ data }) => {
  const {
    meta_title,
    title,
    description,
    button_text,
    button_link,
  } = data.page.data;
  return (
    <Fragment>
      <Head title={meta_title} />
      <Container>
        <Wrapper>
          <LeftWrapper>
            <IllustrationContainer>
              <IllustrationWrapper>
                <NotFoundIllustration show />
              </IllustrationWrapper>
            </IllustrationContainer>
          </LeftWrapper>
          <RightWrapper>
            <TextWrapper>
              <H1>{title.text}</H1>
              <P>{description.text}</P>
              <TextButton to={button_link.url}>{button_text}</TextButton>
            </TextWrapper>
          </RightWrapper>
        </Wrapper>
      </Container>
    </Fragment>
  );
};

export const query = graphql`
  query NotFoundPageQuery {
    page: prismicNotFound {
      data {
        meta_title
        title {
          text
        }
        description {
          text
        }
        button_text
        button_link {
          url
        }
      }
    }
  }
`;

export default NotFoundPage;
